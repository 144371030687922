<template>
  <b-tr>
    <b-td>{{value}}</b-td>
    <b-td class="white-space-nowrap text-right">
      {{cost}}
    </b-td>
  </b-tr>
</template>
<script>

import { formatCurrency } from '../../utils/formatterHelper';
import { isNotEmpty } from '../../utils/validators';

export default {
  name: 'ProductTableRow',
  components: {},
  props: {
    item: Object,
    requestType: String,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    cost() {
      const price = this.decodePricing(this.item);
      if (this.isQuote) return `da ${price}`;
      return price;
    },
    value() {
      return this.item?.value || this.notAvailableMsg;
    },
    isQuote() {
      return isNotEmpty(this.item?.additionalInfos?.info);
    },
  },
  methods: {
    decodePricing(item) {
      const pricing = this.$store.getters['wallet/getPricing'](this.requestType, item.key);
      if (pricing) {
        return formatCurrency(pricing.price.amount, 'it', pricing.price.currency);
      }
      return 'NA';
    },
  },
};
</script>

<style scoped>

</style>
